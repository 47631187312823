require('./js/_custom-vh.js')
import barba from '@barba/core'
import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js'

function autoplay_videos(){
	//Autoplaying Videos:
	document.querySelectorAll('video').forEach(vid => {
		const playPromise = vid.play()
		if (playPromise !== undefined) {
			playPromise.then(_ => {}).catch(error => {
				console.log(error)
			})
		}
	})
}
document.addEventListener('DOMContentLoaded', function() {

	//Darkmode in Systemeinstellungen oder Local Storage erkennen und übernehmen
	if(window.matchMedia('(prefers-color-scheme: dark)').matches || sessionStorage.getItem('darkmode') == 'true'){
		document.getElementsByTagName( 'html' )[0].classList.add('darkmode')
	}

	barba.init({
		timeout: 6000,
		//cacheIgnore: true,
		sync: true,
		transitions: [{
			async once() {},
			async enter() {
				document.getElementById('preloader').style.transform = 'translateY(-120%)'
				//document.getElementById('wrapD3Cube').classList.add('cube_translate')

			},
			async leave() {
				setTimeout(() => {window.scrollTo(0, 0)}, 500)
				const done = this.async()
				document.getElementById('preloader').style.transform = 'translateY(0)'
				//document.getElementById('wrapD3Cube').classList.remove('cube_translate')
				setTimeout(() => done(), 900)
			}
		}],
		views: [
			{
				namespace: 'home',
				beforeEnter(){

				},
				afterEnter() {
					autoplay_videos()
				},
				afterLeave(){
				},
			},
			{
				namespace: 'single-author',
				beforeEnter(){

				},
				afterEnter() {
					autoplay_videos()

					const lebenslauf_toggler = document.getElementById('lebenslauf_toggler')

					if (lebenslauf_toggler) {
						lebenslauf_toggler.addEventListener('click', () => {
							document.getElementById('kachel-student_single').classList.toggle('lebenslauf-out')
						})
					}
				},
				afterLeave(){
				},
			},
			{
				namespace: 'single-projekt',
				beforeEnter(){

				},
				afterEnter() {
					Fancybox.bind('[data-fancybox-href] img', {
						caption: function (fancybox, carousel, slide) {
							const p = slide.$trigger.parentElement.parentElement
							let t = slide.caption
							if (p.nodeName==='FIGURE' && p.getElementsByTagName('figcaption')[0]) {
								t = p.getElementsByTagName('figcaption')[0].innerHTML
							}
							return(t)
						},
						groupAll : true, // Group all items
						on : {
							ready : () => {}
						}
					})
				},
				afterLeave(){
				},
			}
		]
	})

	if ('scrollRestoration' in history) {
		history.scrollRestoration = 'manual'
	}

	barba.hooks.afterLeave(() => {
		document.body.classList.remove('menu_out')
	})

	barba.hooks.enter((data) => {
		//Update body-classes
		let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
			match = regexp.exec(data.next.html)
		if (!match || !match[1]) {
			// If no body class, remove it
			document.body.setAttribute('class', '')
		} else {
			// Set the new body class
			document.body.setAttribute('class', match[1])
		}
	})
	barba.hooks.afterEnter(() => {

		document.querySelectorAll('.menu-button').forEach((menu_button) => {
			menu_button.addEventListener('click',() => {
				document.body.classList.toggle('menu_out')
			})
		})
		document.querySelectorAll('.darkmode_switch').forEach((darkmode_switch) => {
			darkmode_switch.addEventListener('click',() => {
				document.getElementsByTagName( 'html' )[0].classList.toggle('darkmode')
				//In LocalStorage speichern
				document.getElementsByTagName( 'html' )[0].classList.contains('darkmode')
					? sessionStorage.setItem('darkmode', 'true')
					: sessionStorage.setItem('darkmode', 'false')
			})
		})
	})
	//Matomo
	barba.hooks.after(() => {
		_paq.push(['setCustomUrl', location.href])
		_paq.push(['setDocumentTitle', document.title])
		_paq.push(['trackPageView'])
	})
})